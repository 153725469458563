import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class ERPUomService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('unitsofmeasure');
  }

  convertSkuMeasurement(params: {
    skuId: string;
    quantity: number;
    uomFromId: number;
    uomToId: number;
    manufacturerId?: number | number[];
    materialId?: number;
    convertionRate?: number | null;
  }): Observable<number> {
    return this.get<number>(
      {
        ...params,
        rateFromUomId: 16,
        rateToUomId: 7
      },
      'converters/countables/pipes'
    );
  }
}
